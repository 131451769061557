import React, { Component } from 'react';
import MyAccDetails from './myAccDetails';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    checkAccountNumber,
    getOtherDetails,
    getPremiseInfo,
    getAcovInfo
} from '../actions/loginAndSignUpActions';
import { 
    showMessage
} from '../actions/popupMessageActions';
class MyAccNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billAddressSource: '',
            showMessage: 'none',
            resultMessage: '',
            isSuccess: false,
            showSample: false,
            isLoading: false,
            accountId: ''
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(localStorage.getItem("openSampleBill")){
            this.setState({
                showSample: true
            })
            localStorage.removeItem("openSampleBill")
        }
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            showMessage: 'none'
        })
        this.props.checkAccountNumber(this.state.accountId)
        .then((response) => {
            if(response.status === "False"){
                //first check if response.data is array, if true get index 0
                // if(response.description.includes("account is not registered")){
                //     this.setState({ showMessage: 'block', resultMessage: response.description, isLoading: false });
                // }else if {
                if(Array.isArray(response.data)){
                    for(var index = 0; index < response.data.length; index++)
                    {
                        if(response.data[index].accountRelationshipType === "MAIN"){
                            if(response.data[index].billAddressSource === 'PER'){
                                this.setState({
                                    billAddressSource: 'PER'
                                })
                                this.props.getOtherDetails(response.data[index].personId)
                                .then(() => {
                                    this.setState({
                                        isSuccess: true
                                    })
                                })
                            }
                            else if(response.data[index].billAddressSource === 'PREM'){
                                this.setState({
                                    billAddressSource: 'PREM'
                                })
                                this.props.getOtherDetails(response.data[index].personId)
                                .then(() => {
                                    this.props.getPremiseInfo(this.state.accountId)
                                    .then(() => {
                                        this.setState({
                                            isSuccess: true
                                        })
                                    })
                                })
                            }
                            else if(response.data[index].billAddressSource === 'ACOV'){
                                this.setState({
                                    billAddressSource: 'ACOV'
                                })
                                const postData = response.data[index].personAddressOverride
                                this.props.getOtherDetails(response.data[index].personId)
                                .then(() => {
                                    this.props.getAcovInfo(postData)
                                    this.setState({
                                        isSuccess: true
                                    })
                                })
                            }
                            else if((response.data[index].billAddressSource ?? "N/A") == "N/A"){
                                this.setState({
                                    billAddressSource: 'ONLINE'
                                })
                                this.props.getOtherDetails(response.data[index].personId)
                                .then(() => {
                                    this.setState({
                                        isSuccess: true
                                    })
                                })
                            }
                        }
                    }
                }
                else{
                    if(response.data.billAddressSource === 'PER'){
                        this.setState({
                            billAddressSource: 'PER'
                        })
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else if(response.data.billAddressSource === 'PREM'){
                        this.setState({
                            billAddressSource: 'PREM'
                        })
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.props.getPremiseInfo(this.state.accountId)
                            .then(() => {
                                this.setState({
                                    isSuccess: true
                                })
                            })
                        })
                    }
                    else if(response.data.billAddressSource === 'ACOV'){
                        this.setState({
                            billAddressSource: 'ACOV'
                        })
                        const postData = response.data.personAddressOverride
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.props.getAcovInfo(postData)
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else if((response.data.billAddressSource ?? "N/A") == "N/A"){
                        this.setState({
                            billAddressSource: 'ONLINE'
                        })
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                }
            }
            else{
                this.setState({ showMessage: 'block', resultMessage: response.description, isLoading: false })
            }
        })
        .catch((error)=>{
            this.props.showMessage('danger', "Server Error. Try again later!");
        })
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            accountId: e.target.value
        })
    }
    showLogin = () => {
        window.location.replace("/")
    }
    handleClose() {
        this.setState({ showSample: false });
    }
    
    handleShow(e) {
        e.preventDefault();
        this.setState({ showSample: true });
    }
    render() { 
        return ( 
            (this.state.isSuccess === true) ?
            <MyAccDetails billAddressSource={this.state.billAddressSource} accountId={this.state.accountId}/>
            :
            <React.Fragment>
                <Modal show={this.state.showSample} onHide={this.handleClose} dialogClassName="hint-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="hint">
                            <img src={process.env.PUBLIC_URL + '/gwa-bill-preview.png'} alt="find-account-number"/>
                        </div>
                    </Modal.Body>
                </Modal>
                <section id="sign-up-title">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h4 className="primary-font-color">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-stage">
                    <div className="container">
                        <div className="row justify-content-md-center rounded-left rounded-right border-style">
                            <div className="col-lg-4 p-0">
                                <div class="content selected rounded-left">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-1-light.png'} alt="sign-up-1-light"/></span> 
                                        My Account Number
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div class="content border-left-right">
                                    <label className="dark-font-color">
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-2-dark.png'} alt="sign-up-2-dark"/></span> 
                                        My Account Details
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div className="content rounded-right">
                                    <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-dark.png'} alt="sign-up-3-dark"/></span> 
                                    <label className="dark-font-color">
                                        My Login Details
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="sign-up-result" style={{ display: this.state.showMessage }}>
                                    <div className="col-lg-12 text-center">
                                        <label className="secondary-font-color font-bold">{ this.state.resultMessage }</label>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <Form autocomplete="off" onSubmit={this.onSubmit.bind(this)}>
                                        <div className="form-lablel-wrap">
                                            <label className="dark-font-color font-bold">Enter the 10-digit account number <span className="secondary-font-color font-bold">*</span></label>
                                        </div>
                                        <div className="form-input-btn-wrap">
                                            <input name="accountId" type="text" className="form-input" onChange={this.onChange} autocomplete="off" required/>
                                            <button type="submit" className="btn btn-primary form-button regular-font-size font-bold" disabled={this.state.isLoading}>{(this.state.isLoading) ? 'Verifying' : 'Continue'}</button>
                                        </div>
                                        <div className="form-link-wrap">
                                            <a href="/" onClick={this.handleShow} className="secondary-font-color regular-font-size">Where can I find my account number?</a>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form-bottom">
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-lg-6 left">
                                <label>Fields marked as * are mandatory</label>
                            </div>
                            <div className="col-lg-6 right">
                                <label>Already Registered? <span className="primary-font-color" onClick={this.showLogin.bind(this)}>Login Here</span></label>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
export default connect(null, { 
    checkAccountNumber,
    getOtherDetails,
    getPremiseInfo,
    getAcovInfo,
    showMessage
})(MyAccNumber);