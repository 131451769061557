import React, { Component } from 'react';
import { Alert, Table, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../actions/loginAndSignUpActions';
import ForgotPassword from '../components/forgotPassword';
import ForgotUsername from '../components/forgotUsername';
import parse from 'html-react-parser';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

/** FOR SLICK SLIDER */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as moment from 'moment';
import Footer from '../partials/footer';

const loginSignupBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class Login extends Component {
    constructor() {
        super()
        this.state = {
            announcementImageSource: loginSignupBaseURL+'/images/',
            email: '',
            username: '', 
            password: '',
            isLoggedIn: false,
            alertVariant: 'danger',
            items: [],
            isLoading: false,
            isServerError: false,
            disabledLogin: false,
            disabledPay: false,
            announcementsList: [],
            systemPop: [],
            isAnnouncementPop: false,
            isAnnouncement: false,
            showAnnouncement: false,
            errLoginMessage: "",
            modalType: "",
            modalImage: "placeholder-1-e1533569576673-960x960.png",
            modalTitle: "",
            modalContent: "",
            currentYear: moment().format('YYYY'),
            redirectToFaq: false
        }
        this.onChange = this.onChange.bind(this)
    }

    componentWillMount() {
        this.getAnnouncements();
        this.systeMaintenance();
        this.checkIfUserLoggedIn();
        this.renderSliderAnnouncement();
        this.renderPopUpAnnoncements();
        this.checkIfForcedLoggedOut();
    }

    checkIfForcedLoggedOut = () => {
        if(localStorage.getItem("forcedLogout")){
            this.setState({ 
                isLoggedIn: true,  
                alertVariant: 'danger', 
                isLoading: false, 
                errLoginMessage: "You have been logged out."
            }, () => {
                localStorage.removeItem("forcedLogout")
            })
        }
    }

    closeFlagModal = () => {
        this.setState({ showFlag: false, showAnnouncement: false });
    }

    systeMaintenance = () => {
        return axios
        .get('api/v1/system-maintenance', 
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
            if(response.data[0].flag == 1){
                this.setState({disabledLogin: true});
            }else{
                this.setState({disabledLogin: false});
            }
            if(response.data[1].flag == 1) {
                this.setState({disabledPay: true});
            }else{
                this.setState({disabledPay: false});
            }
        });
    }

    getAnnouncements = () => {
        return axios
        .get('/api/v1/announcements-front', 
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
            let announceList = response.data.announceList;
            let systemPop = response.data.systemPop;
            let isAnnouncement = announceList.length == 0 ? false : true;
            let isAnnouncementPop = systemPop.length == 0 ? false : true;
            let showFlag = isAnnouncementPop == false ? false : true;
        
          this.setState({
            announcementsList: announceList,
            isAnnouncement: isAnnouncement,
            systemPop: systemPop,
            isAnnouncementPop: isAnnouncementPop,
            showFlag: showFlag
          });
          
        });
    }

    showAnnouncePopModal = (e) => {
        e.preventDefault();
        let type = e.currentTarget.getAttribute("modaltype");
        let image = e.currentTarget.getAttribute("modalimage");
        let title = e.currentTarget.getAttribute("modaltitle");
        let content = e.currentTarget.getAttribute("modalcontent");

        this.setState({
            modalType: type,
            modalImage: image,
            modalTitle: title,
            modalContent: content,
            showAnnouncement: true,
        });
    }

    renderSliderAnnouncement() {
        let settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                // autoplay: true,
                // speed: 2000,
                // autoplaySpeed: 2000,
                cssEase: "linear"
            };

        let announcements = this.state.announcementsList.map((item, index) => {
            return (
                <div key={index}>
                    <Table className="table announcement-login">
                        <tbody>
                    
                        {
                            item.length == 1  ?
                                item.map((item1, i) => {
                                    let cont = parse(item1.featured_image);
                                    return(
                                        <tr key={i}>
                                            <td className="center-cont">
                                                <div className="container">
                                                <div className="top-cont">
                                                    {
                                                        item1.type == 'Image' ?
                                                        <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}><img width="450" height="300" src={this.state.announcementImageSource+item1.featured_image} /></a>
                                                        :
                                                        <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} className="iframe-click" onClick={this.showAnnouncePopModal.bind(this)}>
                                                        <div className="featured-iframe">
                                                                {cont}
                                                            </div>
                                                        </a>
                                                        
                                                    }
                                                    <div className="cont-left">
                                                        <h6><a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}>{item1.title}</a></h6>
                                                    </div>
                                                </div>
                                                </div>
                                            </td> 
                                        </tr>
                                    ); 
                                })
                            :
                            item.map((item1, i) => {
                                let cont = parse(item1.featured_image);
                                return(
                                    <tr key={i}>
                                        <td width="200px">
                                            {
                                                item1.type == 'Image' ?
                                                <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content}onClick={this.showAnnouncePopModal.bind(this)}><img width="200" height="150" src={this.state.announcementImageSource +item1.featured_image} /></a>
                                                :
                                                <div className="click-a iframe-click" modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}>
                                                <a >
                                                    {cont}
                                                </a>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <h6><a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}>{item1.title}</a></h6>
                                        </td> 
                                    </tr>
                                ); 
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            );
             
        })
        return(
            <div id="announcement-container">
                <div className="with-announcement">
                    <div className="light-font-color">
                        <h1 className="font-extra-bold">WELCOME TO PAYGWA.COM!</h1>
                        <h5 className="font-bold">The official online payment site of {window.screen.width <= 1366 ? <br/> : null} Guam Waterworks Authority</h5>
                    </div>
                </div>
                <Slider {...settings}>
                    {announcements}
                </Slider>
            </div>
        );
    }
    
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        },
        () => {
        })
    }

    checkIfUserLoggedIn = () => {
        //session variable search
        let sessionAccountId;
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex" || Object.keys(localStorage)[count] === 'isAdminLogin' || Object.keys(localStorage)[count] === 'adminusername' || Object.keys(localStorage)[count] === 'level' ){}
            else{
                if(Object.keys(localStorage)[count] === 'accountId'){
                    var accountId = [];
                    var arrAccountId = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                
                    accountId.push([arrAccountId[0], arrAccountId[1]])
                    sessionAccountId = accountId
                }
                else if(Object.keys(localStorage)[count] === 'accountIds'){
                    var evenElements = [], oddElements = [], accountIds = [];
                    var arrAccountIds = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                    for(var count = 0; count < arrAccountIds.length; count++){
                        if(count % 2 === 0){
                            evenElements.push(arrAccountIds[count])
                        }
                        else{
                            oddElements.push(arrAccountIds[count])
                        }
                    }
                    for(var count = 0; count < evenElements.length; count++){
                        accountIds.push([evenElements[count], oddElements[count]])
                    }
                    sessionAccountId = accountIds;
                }
                
            }
        }
        if(sessionAccountId === "" || sessionAccountId === null || sessionAccountId === undefined){
            // localStorage.clear()
        }
        else{
            window.location.replace("/dashboard");
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const viewBillUrl = localStorage.getItem('viewBillURL');
        localStorage.clear();
        this.setState({ isLoading: true, isLoggedIn: false })
        const userData = {
            username: this.state.username,
            password: this.state.password,
        }
        this.props.loginUser(userData)
        .then((result) => {
            const loginSuccessful = result.loginSuccessful
            const status = result.status
            if(!(status === null)){
                this.setState({
                    isServerError: false,
                },() => {
                    if(loginSuccessful.toString() === "true"){
                        document.documentElement.scrollTop = 0;
                        this.setState({ isLoggedIn: true,  alertVariant: 'success'})
                        setInterval(() => { 
                            // alert(localStorage.getItem('viewBillURL'))
                            if(viewBillUrl == null || viewBillUrl == ''){
                                window.location.replace('/dashboard');
                            }else{
                                window.location.replace(viewBillUrl);
                            }
                        }, 1000);
                    }
                    else{
                        document.documentElement.scrollTop = 0;
                        let errMsg = ""
                        if(status.loginMessage === "Exceeded number of wrong password"){
                            errMsg = "You have exceeded the maximum number of failed login attempts" 
                        }
                        else{
                            errMsg = status.loginMessage
                        }
                        this.setState({ 
                            isLoggedIn: true,  
                            alertVariant: 'danger', 
                            isLoading: false, 
                            errLoginMessage: errMsg
                        })
                    }
                })
            }
            else{
                this.setState({
                    isServerError: true,
                    isLoggedIn: true,  
                    alertVariant: 'danger', 
                    isLoading: false,
                })
            }
        })
        .catch((error)=>{
            this.setState({
                isServerError: true,
                isLoggedIn: true,  
                alertVariant: 'danger', 
                isLoading: false,
            })
        })
    }

    goToSignUp = () => {
        window.location.replace('/sign-up');
    }

    renderPopUpAnnoncements () {
        let popUp = this.state.systemPop.map((item, index) => { 
            let it = item.content;
            let con = parse(it);
            return (
                <div className="syst">
                    <h5>{item.title}</h5>
                    {con}
                </div>
            )  
        })
        return (
            <div>
                {popUp}
            </div>
            
        )
    }
    faqOnClick = (e) => {
        e.preventDefault();
        this.setState({
            redirectToFaq: true
        });
        // window.location.replace("/faq");
    }
    render() { 
        let loginMsg = "";
        (this.state.isServerError) ? 
        loginMsg = "Server not responding. Please try again later."
        :
        loginMsg = this.state.alertVariant === 'danger' ? this.state.errLoginMessage : "Success. Please wait for redirection."
        if(this.state.redirectToFaq) {
            return <Redirect to="/faq" />
        }
        return (  
            <React.Fragment>
                <section id="login">
                    <Modal
                        show={this.state.showFlag}
                        onHide={this.closeFlagModal} 
                        size="x"
                        aria-labelledby="contained-modal-title-vcenter"
                        id="modal-with-prompt"
                        backdrop="static"
                        centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div id="system-pop-container">
                                {this.renderPopUpAnnoncements()}
                            </div>
                        </Modal.Body>
                    </Modal>
                
                    <Modal
                        show={this.state.showAnnouncement}
                        onHide={this.closeFlagModal} 
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        id="modal-with-prompt"
                        backdrop="static"
                        centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div id="system-pop-announcement-container">
                                <div className="featured-iframe">
                                {   
                                    this.state.modalType == 'Image' ?
                                    <img width="450" height="300" src={this.state.announcementImageSource+this.state.modalImage} />
                                    :
                                    <div className="iframe-cont">
                                        {parse(this.state.modalImage)}
                                    </div>
                                
                                }
                                </div>
                                <div className="title-cont">
                                    <h5>{this.state.modalTitle}</h5>
                                </div>
                                <p>{parse(this.state.modalContent)}</p>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div className="container-fluid p-0">
                        <div className="row d-none">
                            <div className="col-lg-12 p-0">
                                <div class="announcement-banner-top"><label>Effective June 1, 2020 - all PayGWA.com customers are required to re-enroll to our new enhanced payment system. <a href="/faq">Learn More</a></label></div>
                            </div>
                        </div>
                        
                        <div className="row height-lg-vh">
                            <div className="col-lg-6 p-0 row-eq-height login-banner">
                                
                                {
                                    // this.state.isAnnouncement ? 
                                    //     this.renderSliderAnnouncement()
                                    // :
                                    <div className="custom-column-height">
                                        <div className="padding-right secondary-font-color text-center">
                                            <h1 className="font-extra-bold" style={{fontSize: "40px"}}>Welcome to the new<br/>PAYGWA.com</h1>
                                            {/* <h5 className="font-extra-bold">The official online payment site of {window.screen.width <= 1366 ? <br/> : null} Guam Waterworks Authority</h5> */}
                                            <img style={{width: "100%"}} src={process.env.PUBLIC_URL + '/images/PAY-GWA-HERO.png'} alt="GPA-Logo"/>
                                        </div>
                                    </div>
                                    
                                }
                            </div>
                            <div className="col-lg-6 p-0 row-eq-height">
                                <div className="center-element">
                                    <form onSubmit={e => this.handleSubmit(e)}>
                                        <Alert show={this.state.isLoggedIn} variant={this.state.alertVariant}>
                                            <p>
                                                {loginMsg}
                                            </p>
                                        </Alert>
                                        <div className="login-form">
                                            <div>
                                                <img src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="GPA-Logo"/>
                                            </div>
                                            <div className="pt-5">
                                                {/* <h5 className="dark-font-color big-font-size">Welcome! This is the official online payment site of <br /> Guam Waterworks Authority</h5> */}
                                                <h5 className="dark-font-color big-font-size font-bold">Safe and secure payments - fast!</h5>
                                                <h5 className="dark-font-color big-font-size">Have your account number and billing zip code ready</h5>
                                            </div>
                                            <div className="custom-width">
                                                <div className="pt-2">
                                                        <a href="/paynow"><button type="button" className="btn btn-primary font-bold" onClick={()=>{localStorage.clear(); localStorage.setItem('paynow', "true");}}>Pay Now</button></a>
                                                </div>
                                                <div className="pt-2">
                                                    <img src={process.env.PUBLIC_URL + '/images/card-types.png'} alt="card-types"/>
                                                </div>
                                                <div className="pt-2">
                                                    <h5 className="dark-font-color big-font-size">or</h5>
                                                </div>
                                                <div className="pt-2">
                                                    <label className="dark-font-color font-bold">
                                                        Username
                                                        {/* <OverlayTrigger
                                                            trigger="hover"
                                                            placement="top"
                                                            overlay={
                                                                <Popover id="popover-contained">
                                                                    {"If you have a PayGPWA Mobile App username or password, you can use that here."}
                                                                </Popover>
                                                            }
                                                        >
                                                            <i 
                                                                class="fas fa-info-circle" 
                                                                style={{
                                                                    fontSize: "15px",
                                                                    verticalAlign: "middle",
                                                                    marginLeft: "5px",
                                                                    color: "#08c484"
                                                                }} 
                                                            >
                                                            </i>
                                                        </OverlayTrigger> */}
                                                    </label>
                                                    <input type="text" name="username" className="gpa-form-input" autocomplete="off" onChange={this.onChange.bind(this)}/>
                                                </div>
                                                <div className="pt-2">
                                                    <label className="dark-font-color font-bold">
                                                        Password
                                                        {/* <OverlayTrigger
                                                            trigger="hover"
                                                            placement="top"
                                                            overlay={
                                                                <Popover id="popover-contained">
                                                                    {"If you have a PayGPWA Mobile App username or password, you can use that here."}
                                                                </Popover>
                                                            }
                                                        >
                                                            <i 
                                                                class="fas fa-info-circle" 
                                                                style={{
                                                                    fontSize: "15px",
                                                                    verticalAlign: "middle",
                                                                    marginLeft: "5px",
                                                                    color: "#08c484"
                                                                }} 
                                                            >
                                                            </i>
                                                        </OverlayTrigger> */}
                                                    </label>
                                                    <input type="password" autocomplete="off" name="password" className="gpa-form-input" onChange={this.onChange.bind(this)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="login_actions">
                                            <div className="container custom-width p-0">
                                                <div className="row">
                                                    {/* <div className="col-lg-4 remember-me">
                                                        <label className="">
                                                            <input type="checkbox" className="form-check-input"/>
                                                            Remember Me
                                                        </label>
                                                    </div> */}
                                                    <div className="col-lg-12 forgot text-center">
                                                        <label className="dark-font-color form-check-label">Forgot your <span className="secondary-font-color font-bold"><ForgotUsername open={localStorage.getItem("openForgotUser")}/></span> or <span className="secondary-font-color font-bold"><ForgotPassword open={localStorage.getItem("openForgotPass")}/>?</span></label>
                                                    </div> 
                                                </div>
                                                <div className="pt-2">
                                                        <button type="submit" className="btn btn-primary font-bold"  disabled={this.state.isLoading}>{(this.state.isLoading === true) ? 'Please wait...' : 'Log In'}</button>
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div id="login_help" className="text-center">
                                        <p><a href="/terms-and-conditions" className="secondary-font-color font-bold">Terms and Conditions</a></p>
                                        <p>You must re-enroll to our new system. Click below:</p>
                                        <h5 className=""><span className="secondary-font-color font-bold link-hover" onClick={this.goToSignUp.bind(this)}>Enroll now to your new PayGWA account</span></h5>
                                        {/* <p>Visit our <a href="/" className="secondary-font-color font-bold" onClick={this.faqOnClick.bind(this)}>FAQ page</a> for questions or Call <a className="secondary-font-color font-bold" href="tel:647-7800">647-7800</a> or <a className="secondary-font-color font-bold" href="tel:647-7803">647-7803</a></p>
                                        <p className=""><span>Send us an email at </span><a href="mailto:customers@guamwaterworks.org" className="secondary-font-color font-bold">customers@guamwaterworks.org</a></p> */}
                                    
                                    </div>
                                    {/* <div className="row login-footer text-center">
                                        <div className="col-lg-12">
                                            <p>All Rights Reserved © {this.state.currentYear} Guam Waterworks Authority</p>
                                            {/* <p><a href="/privacy-policy" className="dark-font-color">Privacy Policy</a> |  <a href="/terms-and-conditions" className="dark-font-color">Terms and Conditions</a></p>
                                        
                                            <p><a href="/terms-and-conditions" className="secondary-font-color font-bold">Terms and Conditions</a></p>
                                            
                                        </div>
                                        <div className="col-lg-12 bottom-img">  
                                            <img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img>
                                            <img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks.png"></img>
                                            <img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img>
                                            <img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu.png"></img>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    status: PropTypes.string
}

const mapStateToProps = state => ({
    users: state.users.users,
    status: state.users.status
});

export default connect(mapStateToProps, { loginUser })(Login);