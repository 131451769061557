import React, { Component } from 'react';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import MyLoginDetails from './myLoginDetails';
import MyAccNumber from './myAccNumber';
import { connect } from 'react-redux';
import { 
    validateZipCode,
} from '../actions/paynowActions';

class MyAccDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: 'none',
            resultMessage: '',
            billingZipCode: '',
            isSuccess: false,
            isGoBack: false,
            validated: false,
            showModal: false,
            isVerifying: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    onFormSubmit = (e) => {
        this.setState({ showMessage: 'none',})
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validated: true
            })
        }
        else{
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                isVerifying: true
            }, () => {
                this.props.validateZipCode(this.props.accountId, this.state.billingZipCode)
                .then((success) => {
                    if(success){
                        const result = this.props.paynow.validateZipCodeRes.result
                        if(result.status === "Success"){
                            this.setState({
                                showModal: true,
                                isVerifying: false
                            })
                        }else{
                            this.setState({ showMessage: 'block', resultMessage: result.details});
                            this.setState({
                                isVerifying: false
                            })
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                    this.setState({ showMessage: 'block', resultMessage: "Server Error. Try again later!" });
                    this.setState({
                        isVerifying: false
                    })
                })
            })
        }
    }
    onModalSubmit = (e) => {
        this.setState({
            isSuccess: true
        })
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    showLogin = () => {
        window.location.replace("/")
    }
    goBack = (e) => {
        this.setState({
            isGoBack: true
        })
    }
    handleClose() {
        this.setState({ showModal: false });
    }
    handleShow(e) {
        e.preventDefault();
        this.setState({ showModal: true });
    }
    
    render() { 
        const userBasicInfo = this.props.users.userBasicInfo;
        let fullName  = userBasicInfo.fullName;

        const slice1Fname = fullName.slice(0, 2);
        const slice2Fname = fullName.slice(2).replace(/[\S]/g, "*");
        fullName = slice1Fname+slice2Fname;

        let str = userBasicInfo.fullName;
        let count = 0;
        let new_str = "";
        for(let i = 0; i < str.length; i++){
            if(count > 1){
                if(str.charAt(i) != " "){
                    new_str = new_str + '*'
                    count = count + 1
                }
                else{
                    new_str = new_str + " "
                    count = 0;
                }
                
            }
            else if(count < 2){
                if(str.charAt(i) != " "){
                    new_str = new_str + str.charAt(i)
                    count = count + 1
                }
                else{
                    new_str = new_str + " "
                    count = 0;
                }
            }
            
        }
        fullName = new_str;
        // let firstName = (fullName.substr(0,fullName.indexOf(' ')).trim()).split(" ")[0]
        // let lastName  = fullName.substr(fullName.indexOf(' ')+1)
        // if(lastName === "", fullName.includes(',')){
        //     firstName = (fullName.substr(fullName.indexOf(',')+1).trim()).split(" ")[0]
        //     lastName  = fullName.substr(0,fullName.indexOf(','))
        // }

        // const slice1Fname = firstName.slice(0, 2);
        // const slice2Fname = firstName.slice(2).replace(/[\S]/g, "*");
        // firstName = slice1Fname+slice2Fname;

        // const slice1Lname = lastName.slice(0, 2);
        // const slice2Lname = lastName.slice(2).replace(/[\S]/g, "*");
        // lastName = slice1Lname+slice2Lname;

        let userAddressDetails = this.props.users.userAddressDetails, userPremAddressDetails = this.props.users.userPremAddressDetails, userAcovAddressDetails = this.props.users.userAcovAddressDetails,addressDetails;
        if(this.props.billAddressSource === "PER"){
            addressDetails = userAddressDetails.address1 + ", " + userAddressDetails.city + ", " + userAddressDetails.state + ", " + userAddressDetails.postal + ", " + userAddressDetails.country
        }
        else if(this.props.billAddressSource === "PREM"){
            addressDetails = userPremAddressDetails.address1 + ", " + userPremAddressDetails.city + ", " + userPremAddressDetails.state + ", " + userPremAddressDetails.postal + ", " + userPremAddressDetails.country
        }
        else if (this.props.billAddressSource === "ACOV"){
            addressDetails = userAcovAddressDetails.address1 + ", " + userAcovAddressDetails.city + ", " + userAcovAddressDetails.state + ", " + userAcovAddressDetails.postal + ", " + userAcovAddressDetails.country
        }
        else {
            // For non existing billAddressSource
            addressDetails = userAddressDetails.address1 + ", " + userAddressDetails.city + ", " + userAddressDetails.state + ", " + userAddressDetails.postal + ", " + userAddressDetails.country
        }

        let str1 = addressDetails;
        let count1 = 0;
        let new_str1 = "";
        for(let i = 0; i < str1.length; i++){
            if(count1 > 1){
                if(str1.charAt(i) != " "){
                    new_str1 = new_str1 + 'X'
                    count1 = count1 + 1
                }
                else{
                    new_str1 = new_str1 + " "
                    count1 = 0;
                }
                
            }
            else if(count1 < 2){
                if(str1.charAt(i) != " "){
                    new_str1 = new_str1 + str1.charAt(i)
                    count1 = count1 + 1
                }
                else{
                    new_str1 = new_str1 + " "
                    count1 = 0;
                }
            }
            
        }
        const finalAddressDetails = new_str1;

        // const slice1AddDetails = addressDetails.slice(0, 10);
        // const slice2AddDetails = addressDetails.slice(10).replace(/[\S]/g, "X");
        // const finalAddressDetails = slice1AddDetails+slice2AddDetails;

        const modalMessage = "";

        return ( 
            (this.state.isSuccess === true) ? 
            <MyLoginDetails billAddressSource={this.props.billAddressSource}/>
            :
            (this.state.isGoBack === true) ? 
            <MyAccNumber />
            :
            <React.Fragment>
                <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)} dialogClassName="premise-address-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Confirm Your Address</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="address-details-wrap">
                            <label>{finalAddressDetails}</label>
                        </div>
                        <div className="modal-msg-wrap">
                            <label>Should the above address be incorrect, please select cancel and call GWA at (671) 647-7800/3 (7:30am to 6:00pm) or email us at <a href='mailto:customers@guamwaterworks.org'> customers@guamwaterworks.org</a></label>
                        </div>
                        <div id="signup-form-buttons"> 
                            <div className="left">
                                <Button className="btn-back" onClick={this.handleClose.bind(this)}>Back</Button>
                                <Button className="btn-cancel" onClick={this.showLogin.bind(this)}>Cancel</Button>
                            </div>
                            <div className="right">
                                <Button className="btn-submit" onClick={this.onModalSubmit}>Yes, Proceed</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <section id="sign-up-title">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h4 className="primary-font-color">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-stage">
                    <div className="container">
                        <div className="row justify-content-md-center rounded-left rounded-right border-style">
                            <div className="col-lg-4 p-0">
                                <div class="content done rounded-left">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-check.png'} alt="sign-up-check"/></span> 
                                        My Account Number
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div class="content selected border-left-right">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-2-light.png'} alt="sign-up-2-light"/></span> 
                                        My Account Details
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div className="content rounded-right">
                                    <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-dark.png'} alt="sign-up-3-dark"/></span> 
                                    <label className="dark-font-color">
                                        My Login Details
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="sign-up-result" style={{ display: this.state.showMessage }}>
                                    <div className="col-lg-12 text-center">
                                        <label className="secondary-font-color text-red font-bold">{ this.state.resultMessage }</label>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <Form 
                                        autocomplete="off"
                                        noValidate 
                                        validated={this.state.validated}
                                        onSubmit={this.onFormSubmit.bind(this)}
                                    >
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="first-name">
                                                <Form.Label className="font-bold">Customer Name: <span className="secondary-font-color font-bold">*</span></Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    disabled
                                                    type="text"
                                                    autocomplete="off"
                                                    defaultValue={fullName}
                                                    
                                                />
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please enter your First Name!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="zip-code">
                                                <Form.Label className="font-bold" >GWA Billing Zip Code: (Enter your 5-digit zip code.) <span className="secondary-font-color font-bold">*</span></Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    required
                                                    name="billingZipCode"
                                                    type="text"
                                                    autocomplete="off"
                                                    maxlength="5"
                                                    onChange={this.onChange.bind(this)}
                                                    defaultValue={this.state.billingZipCode}
                                                />
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Zip Code!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <div id="signup-form-buttons">
                                            <div className="left">
                                                <Button className="btn-back" onClick={this.goBack.bind(this)}>Back</Button>
                                                <Button className="btn-cancel" onClick={this.showLogin.bind(this)}>Cancel</Button>
                                            </div>
                                            <div className="right">
                                                <Button className="btn-submit" type="submit" disabled={this.state.isVerifying}>{ this.state.isVerifying ? 'Verifying' : 'Continue' }</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form-bottom">
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-lg-6 left">
                                <label>Fields marked as * are mandatory</label>
                            </div>
                            <div className="col-lg-6 right">
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow,
    users: state.users
});

export default connect(mapStateToProps, { validateZipCode })(MyAccDetails);